<template>
    <BonusPage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import BonusPage from '../components/BonusPage.vue'

export default {
    data: () => ({
    }),
    components: {
        BonusPage
    },
    methods: {
    },
    mounted() {
    }
}
</script>

