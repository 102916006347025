<template>
<div>

    <div class="pb-12">

        <!-- Фильтры -->
        <v-toolbar flat height="70" v-if="collapsed">
            <span class="title primary--text">Фільтр акцій</span>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="$store.commit('openCloseBonusFiltersDrawer', false);">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card outlined width="100%">
        <v-overlay :value="listLoading" absolute opacity='0.15'>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
            <v-chip class="ma-2" outlined v-if="clearable" @click="clearFilters()">
                <v-icon left small>mdi-filter-remove</v-icon>
                <span class="caption">Очистити фільтри</span>
            </v-chip>
            <v-divider></v-divider>
            <!-- {{filters}} -->

            <!-- Статусы -->
            <v-list nav dense class="px-1 ">
                <v-list-group v-model="statusFilter" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2 grey--text text--darken-3">Статус умов</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item @click="loadListR()" v-for="status in statuses" :key="status.id" class="px-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-subtitle class="pr-1">
                                <v-row align="center" class="py-2 px-2">
                                    <v-checkbox v-model="statusM" :value="status.id" hide-details class="filter-item shrink my-3 ml-4 pt-0" :disabled="filters.statusFilter && filters.statusFilter[status.id] ? false : true">
                                        <template v-slot:label>
                                            <span class="subtitle-2">{{status.name}}
                                                <span v-if="filters.statusFilter && filters.statusFilter[status.id] && !status[status.id]" class="grey--text pl-1">
                                                    (<span v-if="Object.keys(status).find(el => status[el])">+</span>{{filters.statusFilter && filters.statusFilter[status.id]}})
                                                </span>
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <v-divider></v-divider>
            <!-- Категории -->
            <v-list nav dense class="px-1">
                <v-list-group v-model="catsFilter" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2 grey--text text--darken-3">Категорія</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item @click="loadListR()" v-for="category in catalogTree().menuItems" :key="category.link" class="px-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-subtitle class="pr-1">
                                <v-row align="center" class="py-2 px-2">
                                    <v-checkbox v-model="cats[category.link]" hide-details class="filter-item shrink my-3 ml-4 pt-0" :disabled="filters.catsFilter && filters.catsFilter[category.link] ? false : true">
                                        <template v-slot:label>
                                            <span class="subtitle-2">{{category.text}}
                                                <span v-if="filters.catsFilter && filters.catsFilter[category.link] && !cats[category.link]" class="grey--text pl-1">
                                                    (<span v-if="Object.keys(cats).find(el => cats[el])">+</span>{{filters.catsFilter && filters.catsFilter[category.link]}})
                                                </span>
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <v-divider></v-divider>
            <!-- Контрагенты -->
            <v-list nav dense class="px-1">
                <v-list-group v-model="contrFilter" active-class="grey--text">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-2 grey--text text--darken-3">Контрагенти</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item @click="loadListR()" v-for="item in contractors" :key="item.id" class="px-0">
                        <v-list-item-content class="pa-0">
                            <v-list-item-subtitle class="pr-1">
                                <v-row align="center" class="py-2 px-2">
                                    <v-checkbox v-model="checkboxContr[item.id]" hide-details class="filter-item shrink my-3 ml-4 pt-0" >
                                        <template v-slot:label>
                                            <span class="subtitle-2">{{item.name}}
                                            </span>
                                        </template>
                                    </v-checkbox>
                                </v-row>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-card>
    </div>
    <v-bottom-navigation v-model="bottomNav" v-if="collapsed" absolute>
        <v-spacer></v-spacer>
        <v-btn value="recent" @click="$store.commit('openCloseBonusFiltersDrawer', false)">
            <span>Показати</span>
            <v-icon>mdi-filter-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
    </v-bottom-navigation>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters

} from 'vuex'
export default {
    components: {},
    props: {
        collapsed: Boolean
    },
    data() {
        return {
            status: {
                current: true
            },
            statusM: 'current',
            bottomNav: false,
            cats: {},
            ress: {},
            types: {},
            checkboxContr: {},
            statuses: [{
                    id: 'current',
                    name: 'Актуальна'
                },
                {
                    id: 'completed',
                    name: 'Виконана'
                },
                {
                    id: 'uncompleted',
                    name: 'Не виконана'
                },
            ],
            statusFilter: true,
            catsFilter: true,
            contrFilter: true,
            newQuery: {}
        }
    },
    methods: {
        ...mapActions(['touch','getBonusContractors']),
        ...mapGetters(['catalogTree']),
        loadList() {
            this.setQuery()
        },
        setQuery() {
            const cats = (Object.keys(this.cats)).filter(el => this.cats[el])
            const contractors = (Object.keys(this.checkboxContr)).filter(el => this.checkboxContr[el])
            delete this.$route.query['cats']
            delete this.$route.query['status']
            delete this.$route.query['contractors']
            Object.assign(this.newQuery, {
                ...this.$route.query,
                cats: cats.length ? cats.join(',') : undefined,
                status: this.statusM || undefined,
                contractors: contractors.length ? contractors.join(',') : undefined, 
            })
            this.$router.push({
                path: '',
                query: this.newQuery
            })
            this.newQuery = {}

        },
        loadListR() {
            this.loadList()
        },
        loadGoodsList() {
            this.searchGoods(this.searchGood)
        },
        clearFilters() {
            this.statusM = ''
            this.cats = {}
            this.loadListR()
        },
        parseQuerySet() {
            const q = this.$route.query
            const cats = q.cats && q.cats.split(',')
            const contractors = q.contractors && q.contractors.split(',')
            this.statusM = q.status || 'current'

            if (cats) {
                for (let id of cats) {
                    this.cats[id] = true
                }
            }
            if(contractors) {
                for (let id of contractors) {
                    this.checkboxContr[id] = true
                }
            }
        },
        folding() {
            //сворачивание неактивных параметров в режиме телефона
            if (this.collapsed) {
                this.statusFilter = false
                this.catsFilter = false
                this.resultsFilter = false
                this.typeFilter = false
                if (Object.keys(this.status).find(el => this.status[el])) {
                    this.statusFilter = true
                }
                if (Object.keys(this.cats).find(el => this.cats[el])) {
                    this.catsFilter = true
                }
            }
        },
    },
    watch: {},
    created() {
        this.getBonusContractors()
        .then(()=> {
            this.parseQuerySet()
            this.setQuery()
        })
        .catch(error => console.log(error))
    },
    mounted() {
        this.folding()
    },
    computed: {
        list() {
            return this.$store.state.bonus.list
        },
        totalItems() {
            return this.$store.state.bonus.total
        },
        listLoading() {
            return this.$store.state.bonus.loading
        },
        filters() {
            return this.$store.state.bonus.filters
        },
        clearable() {
            return Object.keys(this.cats).filter(el => this.cats[el]).length ||
                this.statusM ? true : false
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        contractors() {
            const contractors = this.$store.state.bonus.contractors
            for (let item of contractors) {
                this.checkboxContr[item.id] = true
            }
            return contractors
        },
    },
}
</script>

<style>
.v-text-field.v-text-field--solo.v-input--dense>.v-input__control {
    height: 38px;
}

.filter-item .v-input__slot {
    align-items: start;
}

.filter-item .v-input__slot span {
    white-space: normal;
}
</style>
